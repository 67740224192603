import { createStore } from 'vuex'
declare interface SugAarr {
  id: number;
  first: string;
  last: string;
  place: string;
}
export default createStore({
    state:{
        clicked:"",
        limit:5,
        count:1,
        personArray: [
            { id:0, first: "Sarah", last: "Katz", place: "Las Vegas, NV" },
            { id:1, first: "Malka", last: "Rayman", place: "Monsey, NY" },
            { id:2, first: "Rachel", last: "Schwartz", place: "Brooklyn, NY" },
            { id:3, first: "Leah", last: "Gold", place: "Chicago, IL" },
            { id:4, first: "Shira", last: "Keller", place: "Gadsden, AL" },
            { id:5, first: "Avigayal", last: "Kramer", place: "Los Angeles, CA" },
            { id:6, first: "Bracha", last: "Samberg", place: "St. Louis, MO" },
            { id:7, first: "Rivkah", last: "Nilman", place: "Lakewood, NJ" },
            { id:8, first: "Naomi", last: "Kaufman", place: "Queens, NY" },
            { id:9, first: "Malka", last: "Mittel", place: "Chicago, IL" },
            { id:10, first: "Rina", last: "Harris", place: "Cleavland, OH" },
            
          ] as SugAarr[],

          suggestionsArray: [
            { id:0, first: "Sarah", last: "Katz", place: "Las Vegas, NV" },     
          ] as SugAarr[],

          archiveArray: [

          ] as SugAarr[]
        

    },
    mutations: {
        addToSuggestions(state){
            for (let index = 0; index < 3; index++) {
                if (state.count < state.limit){
                state.suggestionsArray.push(state.personArray[index + state.count]);
                state.personArray[index];
                }
                state.count++;
              }
              console.log(state.personArray)
        },
            archive(state, item){
                const index = state.suggestionsArray.findIndex(
                    obj => obj.id === item);
                //add to archive
                state.archiveArray.push(state.suggestionsArray[index])
                //remove from suggestions
                state.suggestionsArray.splice(index, 1);
                state.count--;

            },

            unarchive(state, item){
                const index = state.archiveArray.findIndex(
                    obj => obj.id === item);

                state.suggestionsArray.push(state.archiveArray[index])
                //remove from suggestions
                state.archiveArray.splice(index, 1);

            },
            clicked(state, item){
                const index = state.personArray.findIndex(
                    obj => obj.id === item);
                    state.clicked = state.personArray[index].first + " " + state.personArray[index].last;
            }
    },
    actions: {},
    getters: {},
    modules:{}
})